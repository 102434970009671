import React, { useState, useEffect } from "react";
import { Row, Container, Col } from "react-bootstrap";
import { Grid, Typography, Button, Stack, Box, Modal } from "@mui/material";
import "./style.css";
const Planos = () => {
  const [contador] = useState(0);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [open2, setOpen2] = React.useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90vw",
    height: "90vh",
    bgcolor: "transparent",

    p: 4,
  };
  useEffect(() => {
    let container_all = document.getElementById("container-body");
    let header = document.getElementById("header");
    let portada = document.getElementById("portada");
    container_all.scrollTo(0, header.scrollHeight + portada.scrollHeight);
  }, [contador]);
  return (
    <Container fluid className="planos-container">
      <Row>
        <h1 style={{ textAlign: "center", fontWeight: "900" }}>
          PLANOS STANDS
        </h1>
      </Row>
      <Row>
        <Col xs={12} md={6} lg={6}>
          <img
            src="https://escuela-bancaria.s3.us-east-2.amazonaws.com/ddd7ad7e-0eaa-48a1-a467-2559efdabbf8.jpeg"
            alt="PLANOS CLAR"
            style={{ width: "90%" }}
            loading="lazy"
            onClick={handleOpen}
          />
        </Col>
        <Col xs={12} md={6} lg={6}>
          <img
            src="https://escuela-bancaria.s3.us-east-2.amazonaws.com/f4751a7b-f3c1-4343-88aa-706b9c8e838e.jpeg"
            alt="PLANOS CLAR"
            style={{ width: "90%" }}
            loading="lazy"
            onClick={handleOpen2}
          />
        </Col>
      </Row>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <img
            src="https://escuela-bancaria.s3.us-east-2.amazonaws.com/ddd7ad7e-0eaa-48a1-a467-2559efdabbf8.jpeg"
            alt="PLANOS CLAR"
            style={{ width: "100%", objectFit: "contain", height: "100%" }}
            loading="lazy"
          />
        </Box>
      </Modal>
      <Modal
        open={open2}
        onClose={handleClose2}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <img
            src="https://escuela-bancaria.s3.us-east-2.amazonaws.com/f4751a7b-f3c1-4343-88aa-706b9c8e838e.jpeg"
            alt="PLANOS CLAR"
            style={{ width: "100%", objectFit: "contain", height: "100%" }}
            loading="lazy"
          />
        </Box>
      </Modal>
    </Container>
  );
};

export default Planos;
