import "./App.css";
import {
  Route,
  Routes,
  BrowserRouter as Router,
  Navigate,
} from "react-router-dom";
import Layout from "./components/layout/Layout";
import Home from "./components/home/Home";
import Unauthorized from "./components/unauthorized/Unauthorized";
import Programa from "./components/programa/programa";
import EnConstruccion from "./components/en-construccion/EnConstruccion";
import Organizadores from "./components/organizadores/Organizadores";
import InfoViaje from "./components/InfoViaje/InfoViaje";
import Tours from "./components/tours/Tours";
import Galeria from "./components/galeria/Galeria";
import Planos from "./components/planos/Planos";
function App() {
  return (
    <Routes>
      <Route estrict path="/" element={<Navigate to="/home" replace />} />
      <Route path="/" element={<Layout />}>
        <Route path="home" element={<Home />} />
        <Route path="en-construccion" element={<EnConstruccion />} />
        <Route path="organizadores" element={<Organizadores />} />
        <Route path="programa" element={<Programa />} />
        <Route path="tours" element={<Tours />} />
        <Route path="galeria" element={<Galeria />} />
        <Route
          path="informacion-importante-para-tu-viaje"
          element={<InfoViaje />}
        />
        <Route path="planos" element={<Planos />} />
      </Route>
      <Route path="*" element={<Unauthorized />} />
    </Routes>
  );
}

export default App;
