import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import "./style.css";
const Tours = () => {
  const [contador] = useState(0);
  useEffect(() => {
    let header = document.getElementById("header");
    let infoViaje = document.getElementById("tours-header");
    window.scrollTo(0, header.scrollHeight + infoViaje.scrollHeight * 0.01);
  }, [contador]);
  return (
    <Container fluid id="tours-container">
      <Row id="tours-header" style={{ padding: "2%", color: "white" }}>
        <h1 style={{ textAlign: "center", fontWeight: "900" }}>
          VIAJA POR GUATEMALA
        </h1>
      </Row>
      <Row style={{ padding: "2%" }}>
        <Col xs={12} md={6} lg={6} style={{ marginTop: "35px" }}>
          <img
            src="https://escuela-bancaria.s3.us-east-2.amazonaws.com/Tours+Guatemala+(1).jpg"
            style={{ width: "100%", objectFit: "contain" }}
          />
        </Col>
        <Col xs={12} md={6} lg={6} style={{ marginTop: "35px" }}>
          <img
            src="https://escuela-bancaria.s3.us-east-2.amazonaws.com/Tours+desde+Antigua+Guatemala+(1).jpg"
            style={{ width: "100%", objectFit: "contain" }}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Tours;
