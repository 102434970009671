import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Row } from "react-bootstrap";
import React from "react";
import "./style.css";
function Header(props) {
  return (
    <Container fluid>
      {/* <Row style={{ display: "grid", placeItems: "center", marginTop: "5%" }}>
        <img
          src="https://escuela-bancaria.s3.us-east-2.amazonaws.com/Logo+COLADE+2023+final+(1).png"
          alt="header"
          style={{ width: "55%", objectFit: "contain" }}
        />
      </Row>
      <Row style={{ display: "grid", placeItems: "center" }}>
        <img
          src="https://escuela-bancaria.s3.us-east-2.amazonaws.com/FECHAS+Y+LUGAR+COLADE.png"
          alt="header"
          style={{ width: "45%", objectFit: "contain" }}
        />
      </Row>*/}

      <Row>
        <Navbar collapseOnSelect expand="xxl" bg="light" variant="light">
          <Container>
            <Navbar.Toggle aria-controls="responsive-navbar-nav justify-content-center" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="mx-auto">
                <Nav.Link href="/">INICIO</Nav.Link>
                <Nav.Link href="/organizadores">ORGANIZADORES</Nav.Link>
                <Nav.Link
                  href="https://escuela-bancaria.s3.us-east-2.amazonaws.com/Brief+FELABAN+CLAR+2024+(9).pdf"
                  target="_blank"
                >
                  PATROCINIOS
                </Nav.Link>
                <NavDropdown title="VENUE" id="collasible-nav-dropdown">
                  <NavDropdown.Item href="/planos">PLANOS</NavDropdown.Item>
                  <NavDropdown.Item
                    href="https://www.tripadvisor.co/Restaurants-g295366-zfp43-Antigua_Sacatepequez_Department.html"
                    target="_blank"
                  >
                    RESTAURANTES
                  </NavDropdown.Item>
                </NavDropdown>
                <NavDropdown
                  title="REGISTRO Y HOSPEDAJE"
                  id="collasible-nav-dropdown"
                >
                  <NavDropdown.Item
                    href="https://registro.felaban.net/congreso/73"
                    target="_blank"
                  >
                    REGISTRO A CLAR
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    href="https://escuela-bancaria.s3.us-east-2.amazonaws.com/Brief+hoteles+y+transporte+CLAR+(1).pdf"
                    target="_blank"
                  >
                    HOTELES Y TRANSPORTE
                  </NavDropdown.Item>
                  <NavDropdown.Item href="https://bit.ly/Congreso_CLAR">
                    HOSPEDAJE HOTEL CASA SANTO DOMINGO
                  </NavDropdown.Item>
                  <NavDropdown.Item href="https://bookings.travelclick.com/106521?groupID=4236775#/guestsandrooms">
                    HOSPEDAJE HOTEL CAMINO REAL
                  </NavDropdown.Item>
                  <NavDropdown.Item href="https://bit.ly/clar2024-pha">
                    HOSPEDAJE PORTA HOTEL
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/informacion-importante-para-tu-viaje">
                    INFORMACIÓN IMPORTANTE PARA TU VIAJE
                  </NavDropdown.Item>
                  <NavDropdown.Item href="https://escuela-bancaria.s3.us-east-2.amazonaws.com/cb272cd5-850b-4650-b3f9-957d01eeb91d.pdf">
                    OTROS HOSPEDAJES
                  </NavDropdown.Item>
                </NavDropdown>
                <NavDropdown title="PROGRAMA" id="collasible-nav-dropdown">
                  <NavDropdown.Item href="/programa">
                    PROGRAMA ACADÉMICO
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/galeria">
                    GALERIA DE LA EXPERIENCIA CLAR
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/tours">TOURS</NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </Row>
    </Container>
  );
}

export default Header;
