import React from "react";
import { Grid, Typography, Stack } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import Segmento from "./Segmento";
const VistaPrevia = (props) => {
  return (
    <React.Fragment>
      <CssBaseline />
      {props.programa == null ? (
        <Grid container alignItems="center" justifyContent="center">
          <Grid item xs={12} md={12} lg={12}>
            <Typography variant="h3"></Typography>
          </Grid>
        </Grid>
      ) : (
        <Stack spacing={5}>
          {props.programa.programa.speakers.map((actual, index) => (
            <Segmento
              actual={actual}
              key={`segmento-${index}`}
              speaker={props.speaker}
            />
          ))}
        </Stack>
      )}
    </React.Fragment>
  );
};

export default VistaPrevia;
