import React, { useEffect, useState } from "react";
import { Container, Row, Carousel, Col } from "react-bootstrap";
import "./style.css";
import Colade from "../layout/footer/sponsors/Colade";
const Galeria = () => {
  const fotos = [
    "https://escuela-bancaria.s3.us-east-2.amazonaws.com/FOTOS+CLAR/13226950_1070635826363518_5565399519798245802_n.jpg",
    "https://escuela-bancaria.s3.us-east-2.amazonaws.com/FOTOS+CLAR/2015.jpg",
    "https://escuela-bancaria.s3.us-east-2.amazonaws.com/FOTOS+CLAR/305759526_767176511398477_3810103024546562525_n.jpg",
    "https://escuela-bancaria.s3.us-east-2.amazonaws.com/FOTOS+CLAR/305761623_767176534731808_9159656138531855494_n.jpg",
    "https://escuela-bancaria.s3.us-east-2.amazonaws.com/FOTOS+CLAR/370348988_1043653587084100_7145589931098819919_n.jpg",
    "https://escuela-bancaria.s3.us-east-2.amazonaws.com/FOTOS+CLAR/371846398_1043653570417435_5916501904390630046_n.jpg",
    "https://escuela-bancaria.s3.us-east-2.amazonaws.com/FOTOS+CLAR/70430573_2474745809285839_657118936747212800_n.jpg",
    "https://escuela-bancaria.s3.us-east-2.amazonaws.com/FOTOS+CLAR/70848698_2474736849286735_2389143161185239040_n.jpg",
    "https://escuela-bancaria.s3.us-east-2.amazonaws.com/FOTOS+CLAR/70903090_2474736829286737_677441991847444480_n.jpg",
    "https://escuela-bancaria.s3.us-east-2.amazonaws.com/FOTOS+CLAR/71017797_2474745752619178_1778147126610493440_n.jpg",
    "https://escuela-bancaria.s3.us-east-2.amazonaws.com/FOTOS+CLAR/71501058_2474744932619260_3259640819136593920_n.jpg",
  ];
  const [contador] = useState(0);
  useEffect(() => {
    let container_all = document.getElementById("container-body");
    let header = document.getElementById("header");
    let portada = document.getElementById("portada");
    container_all.scrollTo(0, header.scrollHeight + portada.scrollHeight);
  }, [contador]);
  return (
    <Container fluid className="galeria-container">
      <Row style={{ padding: "2%" }}>
        <h1 style={{ textAlign: "center", fontWeight: "900" }}>
          GALERIA EXPERIENCIA CLAR
        </h1>
      </Row>
      <Row>
        {fotos.map((actual, index) => (
          <Col xs={12} md={6} lg={4}>
            <img
              src={actual}
              alt={`foto-experiencia-${index}`}
              className="foto-experiencia"
            />
          </Col>
        ))}
      </Row>
    </Container>
  );
};
export default Galeria;
