import { Outlet } from "react-router-dom";
import {
  Container,
  Row,
  Navbar,
  Nav,
  Col,
  Alert,
  Card,
  ListGroup,
  Button,
} from "react-bootstrap";
import React, { useEffect, useState } from "react";
import Header from "./header/Header";
import Footer from "./footer/Footer";
import "./style.css";
import Portada from "./header/Portada";
const Layout = () => {
  const [contador, setContador] = useState(0);
  const [up, setUp] = useState("none");
  const [down, setDown] = useState("none");
  const [pos, setPos] = useState(0);
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  }, [contador]);

  const handleScroll = (evt) => {
    if (window.pageYOffset >= 700) {
      setUp("block");
      setDown("none");
    } else {
      setUp("none");
      setDown("block");
    }
    setPos(window.pageYOffset);
    setContador(contador + 1);
  };

  const goUp = () => {
    window.scrollTo(pos, 0);
  };
  const goBottom = () => {
    let footer = document.getElementById("footer");
    footer.scrollIntoView();
  };
  return (
    <Container
      fluid
      style={{ height: "100vh", width: "100vw", overflow: "hidden" }}
    >
      <Row id="header" style={{ height: "5%" }}>
        <Header />
      </Row>
      <Row style={{ height: "95%", overflowY: "scroll" }} id = 'container-body'>
        <Container fluid>
          <Row id = 'portada'>
            <Portada />
          </Row>
          <Row id="body">
            <Outlet />
          </Row>
          <Row id="footer">
            <Footer />
          </Row>
        </Container>
      </Row>
    </Container>
  );
};

export default Layout;
