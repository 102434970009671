import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import "./style.css";
const InfoViaje = () => {
  const [contador] = useState(0);
  useEffect(() => {
    let container_all = document.getElementById("container-body");
    let header = document.getElementById("header");
    let portada = document.getElementById("portada");
    container_all.scrollTo(0, header.scrollHeight + portada.scrollHeight);
  }, [contador]);
  return (
    <Container fluid id="info-viaje-container">
      <Row id="info-viaje-header">
        <h1 style={{ textAlign: "center", fontWeight: "900" }}>
          INFORMACIÓN IMPORTANTE PARA TU VIAJE
        </h1>
      </Row>
      <Row>
        <Container fluid>
          <Row>
            <h1
              style={{ color: "white", textAlign: "center", fontWeight: "900" }}
            >
              CLIMA EN GUATEMALA
            </h1>
          </Row>
          <Row>
            <Container>
              <Row>
                <Col
                  xs={12}
                  md={12}
                  lg={4}
                  style={{ color: "white", textAlign: "center" }}
                >
                  <img
                    src="https://escuela-bancaria.s3.us-east-2.amazonaws.com/Clima+blanco+(1).png"
                    alt="header"
                    style={{ width: "100%", objectFit: "contain" }}
                  />
                </Col>
                <Col
                  xs={12}
                  md={12}
                  lg={8}
                  style={{
                    color: "white",
                    textAlign: "center",
                    display: "flex",
                    placeItems: "center",
                    padding: "5%",
                  }}
                >
                  <p style={{ textAlign: "left", fontSize: "1em" }}>
                    El tiempo en Guatemala en junio 2024 presenta temperaturas
                    que van desde los 16°C hasta los 24°C.
                    <br />
                    <br />
                    <br />
                    Estas condiciones brindan un clima agradable para explorar
                    Guatemala sin extremos de calor o frío.
                    <br />
                    <br />
                    <br />
                    Con más de 22 días de lluvia pronosticados para este mes, es
                    recomendable el uso de paraguas
                    <br />
                    <br />
                    <br />
                    Para conocer el pronóstico del tiempo actual de manera
                    precisa{" "}
                    <a
                      id="link-clima"
                      href="https://www.tiempo.com/antigua-guatemala.htm"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Ingresa aquí
                    </a>
                  </p>
                </Col>
              </Row>
            </Container>
          </Row>
        </Container>
      </Row>
      <Row className="mt-5">
        <h1 style={{ color: "white", textAlign: "center", fontWeight: "900" }}>
          INFORMACIÓN MIGRATORIA DE GUATEMALA
        </h1>
      </Row>
      <Row className="mt-5">
        <Button
          variant="outline-light"
          href="https://igm.gob.gt"
          target="_blank"
        >
          Click para acceder a información
        </Button>
      </Row>
      <Row className="mt-5">
        <h1 style={{ color: "white", textAlign: "center", fontWeight: "900" }}>
          SERVICIO DE HOTELERÍA Y TRANSPORTE
        </h1>
      </Row>
      <Row className="mt-5">
        <Button
          variant="outline-light"
          href="https://escuela-bancaria.s3.us-east-2.amazonaws.com/Brief+hoteles+y+transporte+CLAR+(1).pdf"
          target="_blank"
        >
          Click para acceder a información
        </Button>
      </Row>
    </Container>
  );
};

export default InfoViaje;
