import React, { useEffect, useState } from "react";
import { Row, Container, Col, Placeholder, Card } from "react-bootstrap";
import "./style.css";
const admin_service = require("../../helpers/admin_service");

const Home = () => {
  const [contador] = useState(0);
  const [evento, setEvento] = useState({});
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    admin_service
      .getData(
        "/evento/view-by-hash/b81db2d1bffc32b75cc58c80a78218b85557281e4193046d2fae9ead1a77643a"
      )
      .then((response_evt) => {
        setLoading(false);
        setEvento(response_evt.data.response_database.result[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [contador]);
  return (
    <Container fluid className="home-container">
      <Row style={{ padding: "5%" }}>
        <Container fluid>
          <Row className="d-flex justify-content-center align-items-center">
            <video
              autoPlay
              muted
              playsInline
              loop
              style={{
                width: "100%",
              }}
            >
              <source
                src="https://escuela-bancaria.s3.us-east-2.amazonaws.com/1718737305+(1).mp4"
                type="video/mp4"
              />
            </video>
          </Row>
          <Row className="d-flex justify-content-center align-items-center mt-5">
            <Col xs={12} md={12} lg={6}>
              <h3>Objetivo del Congreso CLAR</h3>
              <p style={{ textAlign: "justify" }}>
                La novena edición del Congreso Latinoamericano de Riesgos busca
                discutir las experiencias, oportunidades y mejores prácticas
                identificadas por parte de los directivos de riesgos en el
                sector financiero latinoamericano. Similarmente, busca debatir
                la forma en que las áreas de riesgos pueden generar valor a sus
                entidades mediante el entendimiento crítico de las nuevas
                fuentes de riesgos, el uso aplicado de tecnologías de frontera
                aplicadas a la gestión de riesgos, las sinergias que las áreas
                de riesgos pueden liderar e impulsar con otras áreas del negocio
                bancario (comercial, tesorería, legal, cumplimiento y negocios,
                entre otras), y la implementación de mejores prácticas para
                impulsar la competitividad de sus entidades en un entorno de
                negocios cada vez más competido y más cambiante.
              </p>
            </Col>
            <Col xs={12} md={12} lg={6}>
              <img
                src="https://registrofelaban.s3.us-west-2.amazonaws.com/congresos/CLAR2024_Banner.png"
                alt="header"
                style={{ width: "100%" }}
              />
            </Col>
          </Row>
          <Row
            className="d-flex justify-content-center align-items-center mt-5"
            style={{
              backgroundColor: "#2D1012",
              padding: "2%",
              color: "white",
            }}
          >
            <h3 style={{ textAlign: "center" }}>¿Quienes asisten a CLAR?</h3>
            <Col xs={12} md={6} lg={3}>
              {" "}
              <Card
                style={{
                  width: "100%",
                  backgroundColor: "transparent",
                  color: "white",
                  border: "1px solid transparent",
                }}
              >
                <Card.Img
                  variant="top"
                  src="https://escuela-bancaria.s3.us-east-2.amazonaws.com/recursos-humanos.png"
                  style={{ width: "50%" }}
                  className="mx-auto"
                />
                <Card.Body>
                  <Card.Title>
                    + 250 Directivos y Tomadores de Decisiones
                  </Card.Title>
                  <Card.Text>
                    En las áreas de gestión de riesgos de los sectores bancario,
                    cooperativo y financiero.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} md={6} lg={3}>
              <Card
                style={{
                  width: "100%",
                  backgroundColor: "transparent",
                  color: "white",
                  border: "1px solid transparent",
                }}
              >
                <Card.Img
                  variant="top"
                  src="https://escuela-bancaria.s3.us-east-2.amazonaws.com/banco.png"
                  style={{ width: "50%" }}
                  className="mx-auto"
                />
                <Card.Body>
                  <Card.Title>+ 60 Entidades Financieras</Card.Title>
                  <Card.Text>
                    Y Empresas Afines al Sector Tuvieron Representación en este
                    Congreso
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} md={6} lg={3}>
              {" "}
              <Card
                style={{
                  width: "100%",
                  backgroundColor: "transparent",
                  color: "white",
                  border: "1px solid transparent",
                }}
              >
                <Card.Img
                  variant="top"
                  src="https://escuela-bancaria.s3.us-east-2.amazonaws.com/seminario.png"
                  style={{ width: "50%" }}
                  className="mx-auto"
                />
                <Card.Body>
                  <Card.Title>+ 14 conferencistas expertos</Card.Title>
                  <Card.Text>
                    En gestión de riesgos provenientes de América Latina,
                    Estados Unidos y Europa.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} md={6} lg={3}>
              {" "}
              <Card
                style={{
                  width: "100%",
                  backgroundColor: "transparent",
                  color: "white",
                  border: "1px solid transparent",
                }}
              >
                <Card.Img
                  variant="top"
                  src="https://escuela-bancaria.s3.us-east-2.amazonaws.com/america-del-sur.png"
                  style={{ width: "50%" }}
                  className="mx-auto"
                />
                <Card.Body>
                  <Card.Title>+ 17 Países de América Latina</Card.Title>
                  <Card.Text>Participantes</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="d-flex justify-content-center align-items-center mt-5">
            <Col xs={12} md={12} lg={6}>
              {" "}
              {evento.flier == null && (
                <Placeholder as={Container} animation="glow">
                  <Placeholder
                    xs={12}
                    style={{ height: "1000px" }}
                    aria-hidden="true"
                    loading="lazy"
                  />
                </Placeholder>
              )}
              {evento.flier != null && (
                <img
                  src={evento.flier}
                  alt="header"
                  loading="lazy"
                  style={{ width: "100%" }}
                />
              )}
            </Col>
            <Col xs={12} md={12} lg={6} className="mt-5">
              <h3>¿A quién va dirigido?</h3>
              <p style={{ textAlign: "justify" }}>
                El congreso CLAR congrega a altos directivos de las áreas de
                gestión de riesgos financieros (crédito, mercado, liquidez) y no
                financieros (operativo, ciberseguridad), así como responsables
                en áreas de planeación financiera, tesorería, y nuevos negocios
                en entidades bancarias, microfinancieras, cooperativas y cajas
                de ahorro. El congreso también será provechoso para reguladores
                y supervisores financieros, entidades multilaterales, gremios
                económicos, y en general, para todo profesional con interés de
                profundizar en asuntos del sector financiero relacionados con
                una administración estratégica de los riesgos a los cuales se
                enfrentan (y enfrentarán) las entidades del sector bancario de
                América Latina.
              </p>
            </Col>
          </Row>
          <Row className="mt-5">
            <h1>¡Visita Guatemala!</h1>
          </Row>
          <Row className="mt-5">
            <iframe
              width="100%"
              height="600px"
              src="https://www.youtube-nocookie.com/embed/vCccxVvXrTk?si=ebqIdh3i4l9FLJwf"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            ></iframe>
          </Row>
        </Container>
      </Row>
    </Container>
  );
};

export default Home;
